<template>
  <div>
    <div class="row justify-content-md-center">
      <div
        :id="$route.name+'_form'"
        class="col-lg-2 centered warcraft_form auth_form"
      >
        <form
          id="form_login"
          method="post"
          @submit.prevent="onSubmit"
        >
          <div class="form-group">
            <label
              v-if="errors.account"
              for="account"
              class="form-text text-danger"
            >* {{ errors.account | trans }}</label>
            <input
              id="account"
              v-model="account"
              minlength="4"
              maxlength="16"
              :disabled="isSubmitted"
              :placeholder="'lbl_account_name' | trans"
              name="account"
              required
              type="text"
              class="form-control"
            >
            <label
              v-if="errors.password"
              for="password"
              class="form-text text-danger"
            >* {{ errors.password | trans }}</label>
            <input
              id="password"
              v-model="password"
              :minlength="4"
              :maxlength="16"
              :disabled="isSubmitted"
              :placeholder="'lbl_password' | trans"
              name="password"
              required
              type="password"
              class="form-control"
            >
          </div>
          <button
            id="btn_login"
            :disabled="isSubmitted"
            class="btn warcraft_btn"
            name="doLogin"
            type="submit"
            value="Login"
          >
            {{ 'auth_login_title' | trans }}
          </button>

          <router-link
            :to="{ name: 'login_forgotten_index' }"
            class="warcraft_text"
          >
            {{ `link_forgotten` | trans }}
          </router-link>
        </form>
        <br>
        <span>{{ 'auth_register_description' | trans }}</span><br>
        <router-link
          :to="{ name: 'auth_register' }"
          class="btn warcraft_btn"
        >
          {{ 'auth_register_title2' | trans }}
        </router-link>
        <!-- router-link
                  :to="{ name: 'login_forgotten' }"
                  class="warcraft_text">{{ 'auth_lost_authentication' | trans }}</router-link -->
      </div>
    </div>
  </div>
</template>
<script>
'use strict'
import { AccountApi } from "@/api/AccountApi"
import { formDataToJson } from "@/libs/formDataUtils"

export default {

    data () {
        return {
            account: ``,
            password: ``,
            errors: {},
            isSubmitted: false
        }
    },

    mounted () {
        if (this.getUsername()) {
            this.$router.push({ name: `main_home` })
        }
    },

    methods: {
        async onSubmit () {
            this.isSubmitted = true
            const form = document.querySelector(`#form_login`)
            const formData = new FormData(form)
            const postData = formDataToJson(formData)
            try {
                const res = await AccountApi.postCredentials(postData)
                await this.$store.dispatch(`delAlert`, `error_login_failed`)
                console.log(res.data)
                await this.$store.dispatch(`setUser`, res.data)
                await this.$router.push({ name: `main_home` })
            } catch (e) {
                // Connection fail message
                await this.$store.dispatch(`addAlert`, {
                    key: `error_login_failed`,
                    type: `danger`
                })
            } finally {
                this.isSubmitted = false
            }
        }
    }
}
</script>
