<template>
  <div class="col-lg-10 warcraft_article p-3 mb-4">
    <div class="row">
      <div class="col-md-8 col-sm-12">
        <h3>{{ `title_customize` | trans }}</h3>

        <div
          v-for="serviceName in [`customize`, `changerace`, `changefaction`]"
          :key="serviceName"
        >
          <p>
            <character-name :character="character" />
            {{ `description_${serviceName}` | trans }}

            {{ serviceConf[serviceName] }}
            <img
              alt="wine"
              src="/images/wine.webp"
            >
            {{ `description_${serviceName}2` | trans }}
            <nc-link :no-button="true">
              <form
                method="post"
                @submit.prevent="onSubmit(serviceName)"
              >
                <button
                  :disabled="isSubmitted"
                  class="btn warcraft_btn"
                  type="submit"
                >
                  {{ 'btn_buy'|trans }}
                </button>
              </form>
            </nc-link>
          </p>
        </div>
      </div>
      <div
        class="col-md-4 d-none d-md-block"
      >
        <div class="d-flex justify-content-center">
          <div class="parent">
            <div class="mirror" />
            <div class="mirror_background" />
            <div class="mirror_content">
              <character-mirror v-if="$store.getters.getViewerScriptsLoaded" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { ServicesApi } from "@/api/ServicesApi"
import NcLink from "../../ui/NcLink.vue"
import CharacterName from "@/components/ui/CharacterName"
import store from '@/store/store'

const { serviceConf } = require(`character-services`)

export default {
    components: {
        CharacterName,
        NcLink,
        CharacterMirror: () => {
            // Directly use the imported store to access the getter
            if (store.getters.getViewerScriptsLoaded) {
                return import(`@/components/modules/services/ui/CharacterMirror.vue`)
            } else {
                return Promise.reject(new Error(`Scripts not loaded`))
            }
        }
    },

    data () {
        return {
            isSubmitted: false,
            serviceConf
        }
    },

    computed: {

        account () {
            return this.$store.getters.getAccount
        },

        getUser () {
            return this.$store.getters.getUser
        },

        character () {
            return this.$store.getters.getServiceCharacter
        }
    },

    methods: {
        async onSubmit (serviceName) {
            this.isSubmitted = true
            const _this = this
            await this.$store.dispatch(`addAlert`, { key: `info_${serviceName}`, type: `info` })
            ServicesApi.putCommand(serviceName)
                .then(e => {
                    _this.$store.dispatch(`addAlert`, { key: `success_${serviceName}`, type: `success` })
                    _this.$store.dispatch(`loadAccount`)
                })
                .catch(error => {
                    const errorMessage = error.response?.data?.message ?? `error_unexpected`

                    _this.$store.dispatch(`addAlert`, {
                        key: errorMessage,
                        type: `danger`
                    })
                })
                .finally(() => {
                    _this.isSubmitted = false
                    _this.$store.dispatch(`delAlert`, `info_${serviceName}`)
                })
        }
    }
}

</script>
<style scoped>

.parent {
  position: relative;
  min-height: 350px;
  width: 165px;
}

.mirror_content {
  height: 260px;
  overflow: hidden;
  padding-top: 0;
  padding-left: 40px;
  z-index: 2;
  width: 140px;
  margin-left: 10px;
  border-radius: 50px;
}

.mirror {
  position: absolute;
  background-image: url(../../../../public/images/mirror_magic.webp);
  background-repeat: no-repeat;
  left: 0;
  width: inherit;
  height: 354px;
  z-index: 1;
}

.mirror_background {
  position: absolute;
  background: radial-gradient(#444, #000);
  left: 30px;
  top: 105px;
  width: 100px;
  height: 160px;
  z-index: 0;
}
</style>
